

@media (min-width: 601px) {
html {
  font-family: Arial;
  position: relative;
  /* min-height: 100vh; */
}

.p-dark {
  color: #D8D8D8;
}

.span-dark{
  color: #D8D8D8;
}


.parent {
    background-color: #ffffff;
    min-height: 100vh;
}
.parent-dark {
    color: #D8D8D8;
    background-color: #202020;
    min-height: 100vh;
}


.logo-text {
  text-decoration: none; /* no underline */
  color:#3b3939;
}

.image-light{
  filter: grayscale(0%);
}

.image-dark {
  filter: grayscale(100%) brightness(0.6);
}

/*Navigation items*/

/* .active {
  border: 2px;
  padding: 1px;
  border-radius: 5px;
}
 */



/*General hrefs*/

a:visited { 
  text-decoration: none; 
  color:#3b3939;
 }

 a:hover { 
  text-decoration: none; 
  color:#3b3939;
 }
 
 a:link { 
  text-decoration: none; 
  color:#3b3939;
 }


 .a-dark:visited { 
  text-decoration: none; 
  color: #D8D8D8;
 }

 .a-dark:hover { 
  text-decoration: none; 
  color: #D8D8D8;
 }
 
 .a-dark:link { 
  text-decoration: none; 
  color: #D8D8D8;
 }


 .li-dark{
  color: #D8D8D8;

 }
 

 /* a:active { 
  text-decoration: none; 

 }

 a:hover {
  text-decoration: none; 
  
} */

.nav-light{
  text-decoration: none; 
}

.nav-dark{
  text-decoration: none; 

}

.nav-light:hover {
  color: #3b3939;
  padding: 5px;
  border-bottom: 4px solid #738d5e; /* Add or modify the border style here */
}

.nav-dark:hover{
  padding: 5px;
  border-bottom: 4px solid #edededea; /* Add or modify the border style here */
}

.main-body {
  display: table;
  margin: auto;
  vertical-align: top;
  height:100%;
  min-width:500px;
  width:900px;
  max-width:900px;
  
}

.dropdown-content-light{
  display: block;
  position: absolute; /* Update this line */
  min-width: 160px;
  padding: 6px 8px;
  z-index: 101;
  left: 6%;
}



.dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.airport-details-collapse-button{
  justify-content: space-between;
  min-width: 400px;
  text-align:right;
  top:9.2rem;
  position: sticky;
  z-index:999
}


.button-collapse-all-light {
  color: #ffffff;
  background-color: #738d5e;
  border: 2px solid #88af7f;
  font-size:14px;
  border-radius: 5px;
  position: absolute;
  white-space: nowrap;
  top:-45px;
  left: 90%;
  z-index:99
}


.button-collapse-all-dark {
  color: 	#D8D8D8;
  background-color: #707070;
  border: 2px solid 	#A8A8A8;
  font-size:14px;
  border-radius: 5px;
  position: absolute;
  white-space: nowrap;
  top:-45px;
  left: 90%;
  z-index:99
}

.button-collapse-all-light:hover{
  transform: scale(1.1, 1.1);
}



.button-collapse-all-dark:hover{
  transform: scale(1.1, 1.1);
}

.button-dark {
  color: 	#D8D8D8;
  background-color: #707070;
  font-size:14px;
  border-radius: 5px;
  font-size:14px;
}


.button-dark:hover {
  transform: scale(1.1, 1.1);
}

/* Navigation boxes*/

.sticky-header-light {
  position: sticky;
  background: #ffffff;
  min-width: 500px;
  height: 140px;
  top: 0;
  padding-bottom: 0px;
  z-index: 20;
}

.sticky-header-dark {
  position: sticky;
  background: 	#202020;
  min-width: 500px;
  height: 140px;
  top: 0;
  padding-bottom: 0px;
  z-index: 20;
}


.header-nav-light {
  width: 100%;
  min-width: 900px;
  height: 15px;
  background-color: #5A8895;
  color: rgb(182, 182, 182);
  text-align: left;
  vertical-align: middle;
  font-size: 12px;
}

.header-nav-dark {
  width: 100%;
  min-width: 900px;
  height: 15px;
  background-color: #707070;
  color: rgb(182, 182, 182);
  text-align: left;
  vertical-align: middle;
  font-size: 12px;
}

.header-nav-center {
  margin-left:15%;
  display: table;
  margin: auto;
  vertical-align: top;
  min-width:500px;
  width:900px;
  max-width:900px;
}

.footer-light {
  padding-top:0px;
  background-color: #5A8895;
  color: rgb(218, 217, 217);
  text-align: left;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 2.5rem;  
}


.footer-dark {
  padding-top:0px;
  background-color: #707070;
  color: rgb(218, 217, 217);
  text-align: left;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 2.5rem;  
}


.footer-center {
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin-left:15%;
  padding-top:13px;
  margin: auto;
  vertical-align: top;
  min-width:500px;
  width:900px;
  max-width:900px;

}

.footer-center-left {
  display:flex;
  color: #D8D8D8;
}

.footer-center-right {
  display:flex;
  color: #D8D8D8;
}


.header-box-top{
  display:flex;
  justify-content:space-between;
  font-size: 15px;
  flex-direction:row;
}

.header-box-bottom{
  display:flex;
  flex-direction:row;
  justify-content:left;
  font-size: 15px;
  margin-top:12px
  /* border-color: black;
  border-style:dashed;
  border-width: 5px; */
}


.header-logo{
  display: flex;
  justify-content: flex-start;
  z-index:9;
  margin-top:10px;
}


.header-right-top {
  display: flex;
  justify-content: flex-end;
  border:5px;
}

.header-right-top-dark {
  display: flex;
  justify-content: flex-end;
  border:5px;
}


/* Upgraded menu navigation - May 2023 */

.header-box-bottom nav {
  margin:0;
}

.header-box-bottom ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.header-box-bottom li {
  margin-right: 20px;
}

.header-box-bottom:hover li {
  margin-right: 20px;
}

.header-welcome{
  padding:5px;
  display:flex;
  justify-content:center;
  align-items:center;

}

.header-welcome-dark{
  padding:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: #D8D8D8;
}

.header-signout{
  padding:5px;
  display:flex;
  justify-content:center;
  align-items:center;

}

.header-night-mode{
  padding:5px;
  display:flex;
  justify-content:center;
  align-items:center;

}


/*Above styling of page

/* Style the button that is used to open and close the collapsible content */
.collapsible-expand-light {
    background-color: #edededea;
    color: #3b3939;
    cursor: pointer;
    width: auto;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    padding:10px;
  }

  .collapsible-expand-dark {
    background-color: #333333;
    color: #3b3939;
    cursor: pointer;
    width: auto;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    padding:10px;
  }



    
  /* Style the collapsible content. Note: hidden by default */
  .hidden {
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
  }
  

  .button-airport-light {
    background-color: #dadada;
    color: #ffffff;
    background-image: linear-gradient(0.75turn, #738D5E,#71a2cd);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }


  .button-airport-dark {
    background-color: #dadada;
    color: #D8D8D8;;
    background-image: linear-gradient(0.2turn, #404040,#202020);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }



  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .collapsible:hover {
    background-color: #eeeeee91;
  }


  .active, .button:hover {
    background-color: #ccc; 

  }
  


/*Tower open and visual conditions*/

.towerOpen{
  text-align:center;
  width:100px;
  background-color: #88AF7F;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}
.towerClosed{
  text-align:center;
  width:100px;
  padding:2px;
  background-color:#d2d1d1;
  color: #3b3939;
  font-weight: bold;
}

.flightRules-light {
  width:85px;
}
.flightRules-dark {
  color: #D8D8D8;;
  width:85px;
}

.skyVFR{
  text-align:center;
  width:50px;
  background-color: #71A2CD;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}


.skyMVFR{
  text-align:center;
  width:50px;
  background-image: linear-gradient(0.75turn, #71A2CD,#1f1f1f);
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}

.skyIFR{
  text-align:center;
  width:50px;
  background-color: #1f1f1f;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}



.iconWindSock-dark{
  width:45px;
  object-fit:cover;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  object-position: 0px 10px
}
.iconWindSock-light{
  width:45px;
  object-fit:cover;
  object-position: 0px 10px
}


.icon-dark{
  width:30px;
  object-fit:contain;
  filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon-light{
  width:30px;
  filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(92%) contrast(110%);
  object-fit:contain;
}



/*Text headers*/

.headlineText-light{
  padding:2px;
  color:  #424242;
  font-size:1.5em;
  font-weight: bold;
  margin-bottom:20px;

}

.headlineText-dark{
  padding:2px;
  font-size:1.5em;
  color: 	#D8D8D8;
  font-weight: bold;
  margin-bottom:20px;
}



.headerText-light{
  width:20%;
  padding:2px;
  color:  #424242;
  font-weight: bold;

}

.headerText-dark{
  width:20%;
  padding:2px;
  color: 	#D8D8D8;
  font-weight: bold;
}

.windsNotCalm {
  font-size: 15px;
}

/*Details tables - light mode*/
.details-light {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  min-width: 400px;

}

.details-light td, .details-light th {
  border: 1px solid #dadada;
  padding: 8px;
}

.details-light tr:nth-child(even){background-color: #dadada;}

.details-light tr:hover {background-color: #ddd;}

.details-light th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #dadada;
  color: rgba(90, 90, 90, 0.929);
}

/*Details tables - light mode*/
.details-dark {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  min-width: 400px;

}

.details-dark td, .details-dark th {
  border: 1px solid #333333;
  color:#D8D8D8;
  padding: 8px;
}

.details-dark tr:nth-child(even){
  background-color: #202020;

}

.details-dark tr:hover {
  background-color: #1c1c1c;
}

.details-dark th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #202020;
  color:#D8D8D8;
}



/*airport-runways-box*/

  .airport-runway-box {
    display:flex;
    flex-direction: row;
  } 

  .airport-details-container {
    margin-bottom:45px
  }


  .airport-code-name {
    min-width: 400px;
    padding:.1rem;
  }



  .airport-edit {
    width:4rem;
  }


  /*airport-status-and-logos*/

  .airport-status-logos {
    display:flex;
    flex-direction: row;
    border: none;
    justify-content: space-between;
  } 

   .airport-status-light {
    border: none;
   }

   .airport-status-dark {
    color: 	#D8D8D8;
    border: none;
   }


   .airport-logos {
    border: none;
   }

   /*Details and edit page*/

   .details-margin-top{
   margin-top:25px
   }


   /*form validation*/

   .input-error
  {
  border:1px solid red;
  }


  .input-valid
  {
  border:1px solid green;
  }


  #success-message {
    border-left: 5px solid green;
    color: green;
    padding: 10px;
    width:400px;
  }

  #error-message {
    border-left: 5px solid #FF6666;
    color: #FF6666;
    padding: 10px;
    width:400px;
  }



  /*form spacing and line height*/

  form label {
    line-height: 1.6;
  }
  
  form input, form textarea {
    line-height: 1.4;
  }

  /*Announcement*/
  .yellow-ribbon {
    background-color: grey;
    color: white;
    margin-top: 0px;
    padding: 1px;
    text-align: center;
  }

  /*Phraseology preview box*/

  .phraseologyPreview-light {
    padding-top: 5px;
    padding-left: 15px; 
    padding-bottom: 25px;
    background-color: #edededea;

  }

  .phraseologyPreview-dark {
    padding-top: 5px;
    padding-left: 15px; 
    padding-bottom: 25px;

  }
}

  @media (max-width: 600px) {


  html {
    font-family: Arial;
    position: relative;
    min-height: 100vh;
  }
    
  .parent {
    background-color: #ffffff;
  }
  .parent-dark {
    color: #D8D8D8;
    background-color: #202020;
  }

  .main-body {
    display: table;
    margin: auto;
    vertical-align: top;
    min-height: 100vh;
  }
  
  .header-nav-light {
    width: 100%;
    min-width: 600px;
    height: 25px;
    background-color: #5A8895;
    color: rgb(182, 182, 182);
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
  }
  
  .header-nav-dark {
    width: 100%;
    min-width: 600px;
    height: 25px;
    background-color: #707070;
    color: rgb(182, 182, 182);
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
  }

  .sticky-header-light {
    position: sticky;
    background: #ffffff;
    height: 85px;
    top: 0;
    padding-bottom: 0px;
    z-index: 20;
  }
  
  .sticky-header-dark {
    position: sticky;
    background: 	#202020;
    height: 85px;
    top: 0;
    padding-bottom: 0px;
    z-index: 20;
  }

  .button-collapse-all-light {
    color: #ffffff;
    background-color: #738d5e;
    border: 2px solid #88af7f;
    font-size:14px;
    border-radius: 5px;
    /* position: absolute; */
    white-space: nowrap;
    /* top:-60px; */
    left: 90%;
    z-index:99
  }

    
  .header-box-top{
    display:flex;
    justify-content:space-between;
    font-size: 15px;
    flex-direction:row;
  }

  .header-box-bottom{
    display:flex;
    flex-direction:row;
    justify-content:left;
    font-size: 15px;
  }
    
  .airport-details-collapse-button{
    justify-content: space-between;
    text-align:right;
    top:4.5rem;
    position: sticky;
    z-index:999
  }
  
  .header-logo{
    display: flex;
    /* justify-content: flex-start; */
    z-index:9;
    margin-top:10px;
  }

  .header-night-mode{
    padding:5px;
    display:flex;
    justify-content:center;
    align-items:center;
  
  }
  
  .header-welcome{
    padding:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  
  }
  
  .header-welcome-dark{
    padding:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color: #D8D8D8;
  }

  .header-right-top {
    display: flex;
    /* justify-content: flex-end; */
    border:5px;
  }
  
  .header-right-top-dark {
    display: flex;
    /* justify-content: flex-end; */
    border:5px;
  }
  
  .airport-details-container {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:45px
  }

  .airport-code-name {
    max-width: 350px;
    padding:.1rem;
  }
  

  .button-airport-light {
    background-color: #dadada;
    color: #ffffff;
    background-image: linear-gradient(0.20turn, #738D5E,#71a2cd);
    cursor: pointer;
    padding: 18px;
    width: 75px;
    height:75px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;

  }
  .button-airport-dark {
    background-color: #dadada;
    color: #D8D8D8;;
    background-image: linear-gradient(0.5turn, #404040,#202020);
    cursor: pointer;
    padding: 18px;
    width: 75px;
    height:75px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }

  .icon-dark{
    width:40px;
    filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }

  .icon-light{
    width:40px;
    filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(92%) contrast(110%);
  }

  .image-light{
    filter: grayscale(0%);
  }
  
  .image-dark {
    filter: grayscale(100%) brightness(0.6);
  }

  .footer-center {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    margin-left:15%;
    padding:13px;
    margin: auto;
    vertical-align: top;  
  }
  
  .footer-center-left {
    display:flex;
    color: #D8D8D8;
  }

  .footer-center-right {
    display:flex;
    color: #D8D8D8;
  }
  .footer-light {
    padding-top:0px;
    background-color: #5A8895;
    color: rgb(218, 217, 217);
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-width: 900px;
    height: 2.5rem;  
  }
  
  
  .footer-dark {
    padding-top:0px;
    background-color: #707070;
    color: rgb(218, 217, 217);
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-width: 900px;
    height: 2.5rem;  
  }
  
  .button-collapse-all-light {
    color: #ffffff;
    background-color: #738d5e;
    border: 2px solid #88af7f;
    font-size:14px;
    border-radius: 5px;
    white-space: nowrap;
    left: 90%;
    z-index:99;
    padding: 10px 10px; /* Adjust the padding values to change the size */
  }
  
  
  .button-collapse-all-dark {
    color: 	#D8D8D8;
    background-color: #707070;
    border: 2px solid 	#A8A8A8;
    font-size:14px;
    border-radius: 5px;
    white-space: nowrap;
    left: 90%;
    z-index:99;
    padding: 10px 10px; /* Adjust the padding values to change the size */

  }

    .collapsible-expand-light {
      background-color: #edededea;
      color: #3b3939;
      cursor: pointer;
      width: auto;
      border: none;
      text-align: left;
      outline: none;
      font-size: 15px;
      padding:10px;
    }

    .collapsible-expand-dark {
      background-color: #333333;
      color: #3b3939;
      cursor: pointer;
      width: auto;
      border: none;
      text-align: left;
      outline: none;
      font-size: 15px;
      padding:10px;
    }

    .hidden {
      display: none;
      overflow: hidden;
      background-color: #f1f1f1;
    }
  
    .headerText-light{
      width:50%;
      padding:2px;
      color:  #424242;
      font-weight: bold;
    
    }
    
    .headerText-dark{
      width:50%;
      padding:2px;
      color: 	#D8D8D8;
      font-weight: bold;
    }

    /*Details tables - light mode*/
.details-light {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;

}

.details-light td, .details-light th {
  border: 1px solid #dadada;
  padding: 8px;
}

.details-light tr:nth-child(even){background-color: #dadada;}

.details-light tr:hover {background-color: #ddd;}

.details-light th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #dadada;
  color: rgba(90, 90, 90, 0.929);
}

/*Details tables - light mode*/
.details-dark {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;

}

.details-dark td, .details-dark th {
  border: 1px solid #333333;
  color:#D8D8D8;
  padding: 8px;
}

.details-dark tr:nth-child(even){
  background-color: #202020;

}

.details-dark tr:hover {
  background-color: #1c1c1c;
}

.details-dark th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #202020;
  color:#D8D8D8;
}

/*Tower open and visual conditions*/

.towerOpen{
  text-align:center;
  width:100px;
  background-color: #88AF7F;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}
.towerClosed{
  text-align:center;
  width:100px;
  padding:2px;
  background-color:#d2d1d1;
  color: #3b3939;
  font-weight: bold;
}

.flightRules-light {
  width:85px;
}
.flightRules-dark {
  color: #D8D8D8;;
  width:85px;
}

.skyVFR{
  text-align:center;
  width:50px;
  background-color: #71A2CD;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}


.skyMVFR{
  text-align:center;
  width:50px;
  background-image: linear-gradient(0.75turn, #71A2CD,#1f1f1f);
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}

.skyIFR{
  text-align:center;
  width:50px;
  background-color: #1f1f1f;
  padding:2px;
  color: #ffffff;
  font-weight: bold;
}



.iconWindSock{
  width:45px;
  object-fit:cover;
  object-position: 0px 10px
}


.icon-dark{
  width:30px;
  object-fit:contain;
  filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon-light{
  width:30px;
  filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(92%) contrast(110%);
  object-fit:contain;
}

.airport-status-light {
  border: none;
 }

 .airport-status-dark {
  color: 	#D8D8D8;
  border: none;
 }

 .p-dark {
  color: #D8D8D8;
}


.iconWindSock-dark{
  width:45px;
  object-fit:cover;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  object-position: 0px 10px
}
.iconWindSock-light{
  width:45px;
  object-fit:cover;
  object-position: 0px 10px
}

  /*Announcement*/
  .yellow-ribbon {
    background-color: grey;
    color: white;
    margin-top: 0px;
    padding: 1px;
    text-align: center;
  }


  }